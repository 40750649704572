import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { CustomerEntity, CustomerInList } from '../models/customer.model';
import { UserService } from './user.service';
import { getAllDocs, getOneDoc } from '../util/helpers';
import { environment } from 'src/environments/environment';
import { BackendApiService } from './backend-api.service';
import { UserEntity } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private minerUrl = environment.minerURL;

  constructor(
    private firestore: AngularFirestore,
    private userService: UserService,
    private backendApi:BackendApiService
  ) { }

  async getAllCustomers(user: UserEntity): Promise<CustomerInList[]> {
    if (user.jarvisml_group) {
      const customers = await this.backendApi.get("/aidaptive_customer_settings/list", this.minerUrl);
      return customers;
    } else if (user.cid) {
      const customer = await this.backendApi.get(`/aidaptive_customer_settings/get?cid=${user.cid}`, this.minerUrl);
      return [customer];
    } else {
      return [];
    }
  }

  async getCustomer(cid?: string): Promise<CustomerEntity> {
    if (!cid) {
      cid = (await this.userService.getUser()).cid;
    }
    const doc = this.firestore.doc<CustomerEntity>(`Customers/${cid}`);
    return getOneDoc(doc);
  }

  public async getCustomerInfo(cid: string): Promise<any> {
    return await this.backendApi.get(`/aidaptive_customer_settings/get?cid=${cid}`,this.minerUrl);
  }

  async addCustomer(cid: string, data: CustomerEntity): Promise<void>{
    return this.firestore.collection<CustomerEntity>(`Customers`).doc(cid).set(data);
  }

  async getAllCampaigns(cid: string): Promise<any> {
    const collection = this.firestore.collection<any>(`Customers/${cid}/SalesBooster`);
    return getAllDocs(collection);
  }

  async getCampaignDetails(cid: string, campaignId: string): Promise<any> {
    const doc = this.firestore.doc<any>(`Customers/${cid}/SalesBooster/${campaignId}`);
    const snapshot = await doc.get().toPromise();
    return { ...snapshot.data()};
  }

  async getIntegrationSetup(cid: string, provider: string): Promise<DocumentData> {
    return getOneDoc(this.firestore.doc(`/Customers/${cid}/Integrations/${provider}`));
  }

  async setupIntegration(cid: string, provider: string, data: any): Promise<void> {
    const doc = this.firestore.doc(`/Customers/${cid}/Integrations/${provider}`);
    return doc.set(data, { merge: true });
  }

  async savePinnedConfig(cid: string, graphConfig: any): Promise<void> {
    const doc = this.firestore.doc(`/Customers/${cid}/Settings/dashboard`);
    const configData = {};
    configData[graphConfig.id] = {
      pinned: true,
      pinnedConfig: graphConfig.pinnedConfig
    };
    return doc.set(configData, { merge: true });
  }

  async removePin(cid: string, graphConfig: any): Promise<void> {
    const doc = this.firestore.doc(`/Customers/${cid}/Settings/dashboard`);
    const configData = {};
    configData[graphConfig.id] = {
      pinned: false,
    };
    return doc.set(configData, { merge: true });
  }

  async getGraphConfigs(cid: string): Promise<any> {
    const doc = this.firestore.doc<any>(`Customers/${cid}/Settings/dashboard`);
    const snapshot = await doc.get().toPromise();
    return { ...snapshot.data()};
  }

}
