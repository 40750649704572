// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  inferenceURL: "https://api.dev.jarvisml.com",
  insightsURL: "https://api.dev.jarvisml.com",
  minerURL: "https://miner.dev.aidaptive.com",
  spacesURL: "https://api.spaces.dev.jarvisml.com",
  backendAPI: "https://internal-api.dev.jarvisml.com",
  newBackendAPI: "https://api.dev.aidaptive.com",
  uatuAPI: "https://uatu-api-server-dot-jarvis-ml-dev.uc.r.appspot.com",
  firebase:  {
    apiKey: "AIzaSyCZlU7hDxKXM8ycQD4ahmw-uyn4pksoGQA",
    authDomain: "jarvis-ml-dev.firebaseapp.com",
    databaseURL: "https://jarvis-ml-dev-default-rtdb.firebaseio.com",
    projectId: "jarvis-ml-dev",
    storageBucket: "jarvis-ml-dev.appspot.com",
    messagingSenderId: "120200376781",
    appId: "1:120200376781:web:859af16da847d00ed15484",
    measurementId: "G-CBN7YS2CT9" // optional for Firebase JS SDK v7.20.0 and later
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
